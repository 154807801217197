import React, { ChangeEvent } from "react";

interface TextInputProps extends React.HTMLProps<HTMLInputElement> {
    id?: string;
    label?: string;
    className?: any;
    labelStyles?: string;
    inputStyles?: string;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const TextInput = React.forwardRef(
    (props: TextInputProps, ref?: React.Ref<HTMLInputElement>) => {
        const { id, label, className, required, ...inputProps } = props;

        return (
            <div>
                <label
                    htmlFor={id}
                    className="block text-sm font-medium text-gray-700"
                >
                    {label}{" "}
                    {required && <span className="text-red-500">*</span>}
                </label>
                <div className="mt-1">
                    <input
                        id={id}
                        type="text"
                        className="shadow-sm focus:ring-primary-500 focus:border-primary-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        ref={ref}
                        {...inputProps}
                        required={required}
                    />
                </div>
            </div>
        );
    }
);

export default TextInput;
