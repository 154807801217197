import React, { useEffect } from "react";
import { connect } from "react-redux";
import { resetCart } from "store/actions/cart.action";
import { useNavigate } from "@reach/router";
import Button from "components/utils/button.component";
import QRCode from "qrcode.react";
import { Helmet } from "react-helmet";
import { useConfig } from "hooks/use-config.hook";
import { translationHelper } from "helpers/translation.helper";
import axios from "axios";
import { OrderTicketInterface } from "../../interfaces/order-ticket.interface";
import {StorageKeys} from "../../interfaces/storage-keys.enum";

interface PaymentPaidProps {
    resetCart: () => void;
    order_number: string;
}

const PaymentPaid: React.FC<PaymentPaidProps> = (props) => {
    const navigate = useNavigate();
    const config = useConfig();

    const [ticket, setTicket] = React.useState<OrderTicketInterface | null>(
        null
    );

    const [runCheck, setRunCheck] = React.useState<boolean>(true);

    // TODO: Poistoon
    const checkTickets = async () => {
        const cartId = localStorage.getItem(StorageKeys.EVENTIO_CART_ID);
        const checkoutId = localStorage.getItem("last_checkout_id");
        if (!cartId || !checkoutId) {
            setRunCheck(false);
            return;
        }
        try {
            const request = await axios.get(
                `${process.env.GATSBY_WS_API_URL}/v2/carts/${cartId}/checkout/${checkoutId}/order_tickets.json`,
                {
                    headers: {
                        "x-eventio-webservice-id": process.env.GATSBY_WS_ID,
                    },
                }
            );

            setTicket(
                request.data.tickets
                    .filter((t: any) => t.flags.includes("stream"))
                    .sort(
                        (a: any, b: any) =>
                            new Date(a.events[0].start_date).getTime() -
                            new Date(b.events[0].start_date).getTime()
                    )[0]
            );
            setRunCheck(request.data.tickets.length === 0);
        } catch (error) {}
        setRunCheck(false);
    };

    props.resetCart();

    useEffect(() => {
        if (typeof window !== "undefined") {
            sessionStorage.removeItem("last_known_cart_id");
            const orders = localStorage.getItem("orders") || "[]";
            const parsedOrders = JSON.parse(orders).filter(
                (order: string) => order !== props.order_number
            );
            parsedOrders.push(props.order_number);
            localStorage.setItem("orders", JSON.stringify(parsedOrders));

            if (window.fbq) {
                window.fbq("track", "Purchase", {
                    value: 0.0,
                    currency: "EUR",
                });
            }
            sessionStorage.clear();
        }
    }, []);
/*
    useEffect(() => {
        // Haetaan heti tiedot
        checkTickets();
        if (!runCheck) {
            return;
        }

        const checkLoop = setInterval(async () => {
            if (!runCheck) {
                clearInterval(checkLoop);
            }
            await checkTickets();
        }, 5 * 1000);

        return () => {
            clearInterval(checkLoop);
        };
    }, [runCheck]);

    useEffect(() => {
        setTimeout(() => {
            setRunCheck(false);
        }, 60 * 1000);
    }, []);*/

    return (
        <>
            <Helmet>
                <title>Kiitos! - {translationHelper(config.title, "fi")}</title>
            </Helmet>
            <div className="w-full sm:rounded-md bg-white overflow-hidden sm:shadow py-2 flex justify-center content-center text-center">
                <div className="block">
                    <svg
                        className="w-20 h-20 text-green-600 inline-flex justify-center"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                    >
                        <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                            clipRule="evenodd"
                        />
                    </svg>
                    <h1 className="text-xl mb-2">Kiitos tilauksestasi!</h1>
                    <QRCode
                        renderAs="canvas"
                        value={props.order_number}
                        fgColor="#2C2E35"
                        includeMargin={false}
                        size={128}
                        className="m-auto mb-2"
                    />
                    <p className="mb-4">
                        Tilausnumerosi on <b>{props.order_number}</b>
                    </p>
                    <div className="mb-4">
                        {ticket && (
                            <Button
                                color="primary"
                                type="button"
                                onClick={() =>
                                    navigate(
                                        "/stream/?ticket_code=" +
                                            ticket?.ticket_code
                                    )
                                }
                                fullWidth={true}
                            >
                                Siirry katsomaan
                            </Button>
                        )}
                    </div>
                    {typeof window !== "undefined" &&
                    localStorage.getItem("current_url") ? (
                        <Button
                            color="primary"
                            type="button"
                            onClick={() =>
                                navigate(
                                    localStorage.getItem("current_url") || "/"
                                )
                            }
                            fullWidth={true}
                        >
                            Palaa tapahtumaan
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            type="button"
                            onClick={() => navigate("/")}
                            fullWidth={true}
                        >
                            Siirry etusivulle
                        </Button>
                    )}
                </div>
            </div>
        </>
    );
};

const mapDispatchToProps = (dispatch: any) => ({
    resetCart: () => dispatch(resetCart()),
});

export default connect(null, mapDispatchToProps)(PaymentPaid);
