import React from "react";

import PaymentPaid from "./payment-paid.component";
import { RouteComponentProps, useNavigate } from "@reach/router";
import Layout from "../layout.component";

interface TempPaymentProps extends RouteComponentProps {
    order_id: string;
    order_number: string;
    payment_request_id: string;
    type: string;
}

const TempPayment: React.FC<TempPaymentProps> = (props) => {
    const { order_number, type } = props;

    const navigate = useNavigate();

    if (type === "pw")
        return (
            <Layout>
                <PaymentPaid order_number={order_number} />
            </Layout>
        );

    if (type === "cnl") {
        navigate("/cart/cancelled");
        return <p>Uudelleenohjataan...</p>;
    }

    return <p>Jokin meni vikaan!</p>;
};

export default TempPayment;
