import React from "react";

const NoSSR: React.FC = (props) => {
    const [canRender, setCanRender] = React.useState(false);

    React.useEffect(() => setCanRender(true), []);

    const { children } = props;

    return canRender ? <>{children}</> : <span></span>;
};

export default NoSSR;
