import React from "react";
import { payOrder } from "store/actions/payment.action";
import TextInput from "../components/utils/TextInput";
import Layout from "../components/layout.component";
import NoSSR from "../components/utils/no-ssr.component";
import { useAppDispatch } from "../hooks/use-redux.hook";

const PayOrderForm: React.FC = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const [order, setOrder] = React.useState(urlParams.get("order") || "");

    const dispatch = useAppDispatch();

    const postForm = () => {
        dispatch(payOrder(order));
    };

    if (urlParams.get("continue") && urlParams.get("continue") === "1") {
        postForm();
    }

    return (
        <>
            <form
                onSubmit={(event) => {
                    event.preventDefault();
                    postForm();
                }}
            >
                <TextInput
                    value={order}
                    onChange={(event) => setOrder(event.target.value)}
                    label="Tilausnumero"
                />
                <span className="inline-flex rounded-md shadow-sm my-2 w-full">
                    <button
                        type="submit"
                        className="w-full justify-center items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded text-white bg-primary-600 hover:bg-primary-500 focus:outline-none focus:border-primary-700 active:bg-primary-700 transition ease-in-out duration-150"
                    >
                        Maksa tilaus
                    </button>
                </span>
            </form>
        </>
    );
};

const PayOrder: React.FC = () => {
    return (
        <Layout>
            <div
                className="sm:rounded-md bg-white overflow-hidden sm:shadow py-2 flex justify-center content-center text-left">
                <div className="block">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                        Maksa tilaus
                    </h3>
                    <div className="mt-2 mb-4 max-w-xl text-sm leading-5 text-gray-500">
                        <p>
                            Voit maksaa tilauksesi helposti
                            verkkomaksutunnuksillasi tai Visa / MasterCard
                            -kortilla.
                        </p>
                        <p>
                            Syötä tilausnumerosi alla olevaan kenttään ja
                            klikkaa "Maksa tilaus" -painiketta.
                        </p>
                    </div>
                    <NoSSR>
                        <PayOrderForm />
                    </NoSSR>
                </div>
            </div>
        </Layout>
    );
};

export default PayOrder;
