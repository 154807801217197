import {PaymentMethodInterface} from "interfaces/payment-method.interface";
import {PaymentStatus} from "store/reducers/payment.reducer";
import axios from "axios";
import {PaymentActionType} from "interfaces/payment-action-type.enum";

export const selectPaymentMethod = (payment_method: PaymentMethodInterface) => (
    dispatch: any,
    getState: any
) => {
    dispatch({
        type: PaymentActionType.SET_PAYMENT_STATUS,
        status: PaymentStatus.PAYMENT_PENDING,
    });

    setTimeout(
        () =>
            Math.random() >= 0.5
                ? dispatch({
                    type: PaymentActionType.SET_PAYMENT_STATUS,
                    status: PaymentStatus.CANCELLED,
                })
                : dispatch({
                    type: PaymentActionType.SET_PAYMENT_STATUS,
                    status: PaymentStatus.PAID,
                }),
        3000
    );
    console.log(payment_method);
};

export const payOrder = (order: string) => async () => {
    const id = process.env.GATSBY_WS_ID;
    const result = await axios.post(
        `${process.env.GATSBY_WS_API_URL}/v1/${id}/orders/pay_order`,
        {order_code: order}
    );

    if (result.data.payment_available) {
        window.location = result.data.forward_to_url;
    }
};

export const cancelPayment = () => {
    console.log("Cancelled payment");
};
