import React from "react";

interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
    color: string;
    textColor?: string;
    type: "button" | "submit" | "reset" | undefined;
    fullWidth?: boolean;
    disabled?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
    const {
        type,
        color,
        label,
        children,
        fullWidth,
        disabled,
        title,
        ...buttonProps
    } = props;

    const textColor = props.textColor || "text-white";

    return (
        <button
            type={type}
            disabled={disabled}
            title={title}
            className={`
                ${fullWidth ? "w-full" : ""}
                px-4 
                py-2 
                border 
                border-transparent 
                text-base 
                font-medium 
                rounded-md 
                shadow-sm 
                bg-${color}-600 
                hover:bg-${color}-700 
                focus:outline-none 
                focus:ring-2 
                focus:ring-offset-2 
                focus:ring-${color}-500
                text-center
                ${textColor}
                ${disabled ? "cursor-not-allowed pointer-events-none bg-" + `${color}` + "-200" : ""}
                `}
            {...buttonProps}
        >
            {label || children}
        </button>
    );
};
export default Button;
